/*jslint node: true */
"use strict";
/*globals mod, masterUtils, _, angular, window, navigator, document, $, URL */

angular.module('Bookings', [])
    .factory('Bookings', ['$http', '$q', 'myConfig', 'MyAccount', 'PromotionService',
        function ($http, $q, myConfig, MyAccount, PromotionService) {
            let cachedInfo = null;
            let cachedAvailability;
            let cachedLangAvailability;
            let cachedPromotionCodeAvailability = "";
            let cachedBooking = null;
            let cachedDoc = null;
            let cachedLanguage = 'en';
            let cachedPromotionCode = "";
            let cachedBookingForm = null;
            let cachedExperiences = {budgets: []};
            let lastNormalizedBookingFormParams;
            let staticHostUrl = myConfig.production ? myConfig.middlewareHost : myConfig.host;
            if (staticHostUrl.substr(-1) === '/') {
                staticHostUrl = staticHostUrl.substr(0, staticHostUrl.length - 1);
            }
            document.body.classList.add('loading');

            const DU = masterUtils.dateUtils;

            let cachedInfoData = null;
            let cachedTranslations = null;
            let countryAges = {
                processed: false,
                DEFAULT: {
                    diff: {
                        middleAge: 30,
                        oldAge: 70
                    },
                    offset: {
                        middleAge: 10,
                        oldAge: 10
                    },
                    PassDiff: {
                        middleAge: 30,
                        oldAge: 70
                    },
                    PassOffset: {
                        middleAge: 10,
                        oldAge: 10
                    }
                }
            };

            function loadLanguageSelector(langs, availableLangs = []) {
                const floatingRightBox = document.getElementById('floating-right-box');
                let langSelectorContainer = document.getElementById('language-selector-container');

                if (langSelectorContainer == null) {
                    langSelectorContainer = document.createElement('div');
                    langSelectorContainer.id = 'language-selector-container';
                    langSelectorContainer.style.display = 'none';
                    floatingRightBox.appendChild(langSelectorContainer);
                }

                if (langSelectorContainer != null) {
                    langSelectorContainer.innerHTML = '';
                    const langSelector = document.createElement('select');
                    const lowerAvailableLangs = availableLangs.map((lang) => lang.toLowerCase());
                    const filteredLanguages = langs.filter((lang) => availableLangs.length == 0 || lowerAvailableLangs.includes(lang.ISO.toLowerCase()));
                    filteredLanguages.forEach(function (lang) {
                        const option = document.createElement('option');
                        option.value = lang.ISO;
                        option.text = lang.ISO.toUpperCase();
                        langSelector.appendChild(option);
                    });
                    langSelector.value = myConfig.lang;
                    langSelector.addEventListener('change', function () {
                        // Replace or add lang param to url
                        let newLang = this.value;
                        let url = new URL(window.location.href);
                        url.searchParams.set('lang', newLang);
                        // Refresh the page with the new lang parameter
                        window.location.href = url.href;
                    });
                    langSelectorContainer.appendChild(langSelector);
                    langSelectorContainer.style.display = 'block';
                }
            }

            let refreshFlag = false;

            function validateResponseAndRefreshInfo(promise, data) {
                if (data.products.length === 0 && refreshFlag === false) {
                    refreshFlag = true;
                    cachedInfoData = null;
                    cachedLanguage = null;
                    cachedPromotionCode = null;
                    setTimeout(() => {
                        processInfoData(true)
                            .then(function (info) {
                                promise.resolve(info);
                                document.body.classList.remove('loading');
                            })
                            .catch(function (error) {
                                promise.reject(error);
                                document.body.classList.remove('loading');
                            });
                    }, 5000);
                } else {
                    cachedInfo.resolve(data);
                    document.body.classList.remove('loading');
                }
            }

            function myAccountCheck() {
                return new Promise((resolve, reject) => {
                    $http.get(staticHostUrl + "/api/myAccount/check?idProperty=" + myConfig.idProperty)
                        .success(function (data) {
                            if (data.enabled != null && data.tenantId) {
                                MyAccount.loadMyAccount(data.tenantId, !data.onlyShowWithQueryParam)
                                    .finally(() => {
                                        resolve();
                                    });
                            } else {
                                resolve();
                            }
                        })
                        .error(function (err) {
                            console.error(err);
                            resolve();
                        });
                });
            }

            function processInfoData(withoutCache = false) {
                return new Promise((resolve, reject) => {

                    const myAccountPersonId = MyAccount.getMyAccountPersonId();
                    const myAccountPerson = MyAccount.getPerson();

                    const params = {
                        idProperty: myConfig.idProperty,
                        lang: myConfig.lang,
                        promotionCode: myConfig.promotionCode,
                        includeTranslations: myConfig.GoogleTagManagerLanguage,
                        mode: 'v4',
                        myAccountPersonId: (myAccountPersonId != null) ? myAccountPersonId : null,
                        myAccountPersonEmail: (myAccountPerson != null) ? myAccountPerson.email : null,
                        myAccountPersonLastName: (myAccountPerson != null) ? myAccountPerson.lastName : null,
                    };

                    if (withoutCache) {
                        params.time = new Date().getTime();
                    }

                    $http.get(staticHostUrl + "/api/info", {params})
                        .success(function (data) {

                            if (myConfig.loadLanguageSelector && data.langs != null) {
                                loadLanguageSelector(data.langs, myConfig.availableLangs);
                                document.body.classList.add('language-selector');
                            }

                            if (data.googleEnabled === true) {
                                myConfig.googleEnabled = true;
                                document.body.classList.add('google');
                            }

                            cachedTranslations = data.translations || {
                                categories: {},
                                categoryGroups: {},
                                products: {},
                                productGroups: {},
                                facilities: {},
                            };

                            data.webVisualizationGroups.forEach(function (wvg) {
                                wvg.gallery = [];
                                wvg.icons = [];
                                wvg.maps = [];
                                wvg.plants = [];
                                wvg.principal = null;

                                wvg.multimedia.forEach(function (im) {
                                    if (im.type === 'GAL') wvg.gallery.push(im);
                                    if (im.type === 'ICO') wvg.icons.push(im);
                                    if (im.type === 'MAP') wvg.maps.push(im);
                                    if (im.type === 'PLT') wvg.plants.push(im);
                                    if (im.type === 'PRI' && !wvg.principal) wvg.principal = im;
                                });
                            });

                            data.products.forEach(function (pr) {

                                if (pr.htmlName != null && pr.htmlName !== '') {
                                    pr.name = pr.htmlName;
                                }

                                pr.gallery = [];
                                pr.icons = [];
                                pr.maps = [];
                                pr.plants = [];
                                pr.principal = null;

                                pr.multimedia.forEach(function (im) {
                                    if (im.type === 'GAL') pr.gallery.push(im);
                                    if (im.type === 'ICO') pr.icons.push(im);
                                    if (im.type === 'MAP') pr.maps.push(im);
                                    if (im.type === 'PLT') pr.plants.push(im);
                                    if (im.type === 'PRI' && !pr.principal) pr.principal = im;
                                });
                            });

                            countryAges = data.countryAges;

                            cachedInfoData = data;

                            validateResponseAndRefreshInfo(cachedInfo, data);
                        })
                        .error(function (data, status) {
                            var errorMsg = '';
                            if ((status !== 0) && data) {
                                errorMsg = data.errorMsg;
                            }
                            reject(errorMsg);
                        });
                });
            }

            function getInfo() {
                if (cachedInfo && (cachedLanguage === myConfig.lang) && (cachedPromotionCode === myConfig.promotionCode)) {
                    return cachedInfo.promise;
                }
                cachedLanguage = myConfig.lang;
                cachedPromotionCode = myConfig.promotionCode;
                cachedInfo = $q.defer();

                myAccountCheck()
                    .then(PromotionService.checkAvailablePromotions)
                    .then(() => {
                        return processInfoData();
                    })
                    .then((data) => {
                        cachedInfo.resolve(data);
                    })
                    .catch((error) => {
                        cachedInfo.reject(error);
                        cachedInfo = null;
                    });

                return cachedInfo.promise;
            }

            function getInfoOld() {
                if (cachedInfo && (cachedLanguage === myConfig.lang) && (cachedPromotionCode === myConfig.promotionCode)) {
                    return cachedInfo.promise;
                }
                cachedLanguage = myConfig.lang;
                cachedPromotionCode = myConfig.promotionCode;
                cachedInfo = $q.defer();

                $http.get(staticHostUrl + "/api/info",
                    {
                        params: {
                            idProperty: myConfig.idProperty,
                            lang: myConfig.lang,
                            promotionCode: myConfig.promotionCode,
                            includeTranslations: myConfig.GoogleTagManagerLanguage,
                            mode: 'v4'
                        }
                    })
                    .success(function (data) {

                        if (myConfig.loadLanguageSelector && data.langs != null) {
                            loadLanguageSelector(data.langs, myConfig.availableLangs);
                            document.body.classList.add('language-selector');
                        }

                        cachedTranslations = data.translations || {
                            categories: {},
                            categoryGroups: {},
                            products: {},
                            productGroups: {},
                            facilities: {},
                        };

                        data.webVisualizationGroups.forEach(function (wvg) {
                            wvg.gallery = [];
                            wvg.icons = [];
                            wvg.maps = [];
                            wvg.plants = [];
                            wvg.principal = null;

                            wvg.multimedia.forEach(function (im) {
                                if (im.type === 'GAL') wvg.gallery.push(im);
                                if (im.type === 'ICO') wvg.icons.push(im);
                                if (im.type === 'MAP') wvg.maps.push(im);
                                if (im.type === 'PLT') wvg.plants.push(im);
                                if (im.type === 'PRI' && !wvg.principal) wvg.principal = im;
                            });
                        });

                        data.products.forEach(function (pr) {

                            if (pr.htmlName != null && pr.htmlName !== '') {
                                pr.name = pr.htmlName;
                            }

                            pr.gallery = [];
                            pr.icons = [];
                            pr.maps = [];
                            pr.plants = [];
                            pr.principal = null;

                            pr.multimedia.forEach(function (im) {
                                if (im.type === 'GAL') pr.gallery.push(im);
                                if (im.type === 'ICO') pr.icons.push(im);
                                if (im.type === 'MAP') pr.maps.push(im);
                                if (im.type === 'PLT') pr.plants.push(im);
                                if (im.type === 'PRI' && !pr.principal) pr.principal = im;
                            });
                        });

                        countryAges = data.countryAges;

                        cachedInfoData = data;

                        if (data.myAccount != null && data.myAccount.tenantId) {
                            MyAccount.loadMyAccount(data.myAccount.tenantId, !data.myAccount.onlyShowWithQueryParam)
                                .finally(() => {
                                    cachedInfo.resolve(data);
                                });
                        } else {
                            cachedInfo.resolve(data);
                        }
                    })
                    .error(function (data, status) {
                        var errorMsg = '';
                        if ((status !== 0) && data) {
                            errorMsg = data.errorMsg;
                        }
                        cachedInfo.reject(errorMsg);
                        cachedInfo = null;
                    });

                return cachedInfo.promise;
            }

            function getInfoWithProductsCheck(withoutCache = false) {
                if (cachedInfo && (cachedLanguage === myConfig.lang) && (cachedPromotionCode === myConfig.promotionCode)) {
                    return cachedInfo.promise;
                }
                cachedLanguage = myConfig.lang;
                cachedPromotionCode = myConfig.promotionCode;
                cachedInfo = $q.defer();

                const params = {
                    idProperty: myConfig.idProperty,
                    lang: myConfig.lang,
                    promotionCode: myConfig.promotionCode,
                    includeTranslations: myConfig.GoogleTagManagerLanguage,
                    mode: 'v4'
                };

                if (withoutCache) {
                    params.time = new Date().getTime();
                }

                $http.get(staticHostUrl + "/api/info", {
                    params
                })
                    .success(function (data) {

                        if (myConfig.loadLanguageSelector && data.langs != null) {
                            loadLanguageSelector(data.langs, myConfig.availableLangs);
                            document.body.classList.add('language-selector');
                        }

                        cachedTranslations = data.translations || {
                            categories: {},
                            categoryGroups: {},
                            products: {},
                            productGroups: {},
                            facilities: {},
                        };

                        data.webVisualizationGroups.forEach(function (wvg) {
                            wvg.gallery = [];
                            wvg.icons = [];
                            wvg.maps = [];
                            wvg.plants = [];
                            wvg.principal = null;

                            wvg.multimedia.forEach(function (im) {
                                if (im.type === 'GAL') wvg.gallery.push(im);
                                if (im.type === 'ICO') wvg.icons.push(im);
                                if (im.type === 'MAP') wvg.maps.push(im);
                                if (im.type === 'PLT') wvg.plants.push(im);
                                if (im.type === 'PRI' && !wvg.principal) wvg.principal = im;
                            });
                        });

                        data.products.forEach(function (pr) {

                            if (pr.htmlName != null && pr.htmlName !== '') {
                                pr.name = pr.htmlName;
                            }

                            pr.gallery = [];
                            pr.icons = [];
                            pr.maps = [];
                            pr.plants = [];
                            pr.principal = null;

                            pr.multimedia.forEach(function (im) {
                                if (im.type === 'GAL') pr.gallery.push(im);
                                if (im.type === 'ICO') pr.icons.push(im);
                                if (im.type === 'MAP') pr.maps.push(im);
                                if (im.type === 'PLT') pr.plants.push(im);
                                if (im.type === 'PRI' && !pr.principal) pr.principal = im;
                            });
                        });

                        countryAges = data.countryAges;

                        cachedInfoData = data;

                        if (data.myAccount != null && data.myAccount.tenantId) {
                            MyAccount.loadMyAccount(data.myAccount.tenantId, !data.myAccount.onlyShowWithQueryParam)
                                .finally(() => {
                                    validateResponseAndRefreshInfo(cachedInfo, data);
                                });
                        } else {
                            validateResponseAndRefreshInfo(cachedInfo, data);

                        }
                    })
                    .error(function (data, status) {
                        let errorMsg = '';
                        if ((status !== 0) && data) {
                            errorMsg = data.errorMsg;
                        }
                        cachedInfo.reject(errorMsg);
                        cachedInfo = null;
                    });

                return cachedInfo.promise;
            }

            function getAvailability(agregate = 'PRODUCT') {
                if (cachedAvailability) {
                    if (cachedAvailability.promise.$$state.status === 0) {
                        return cachedAvailability.promise;
                    }
                    if ((cachedPromotionCodeAvailability === myConfig.promotionCode) &&
                        (cachedLangAvailability === myConfig.lang &&
                            agregate === 'PRODUCT')) {
                        return cachedAvailability.promise;
                    }
                }

                cachedPromotionCodeAvailability = myConfig.promotionCode;
                cachedLangAvailability = myConfig.lang;
                cachedAvailability = $q.defer();
                const params = {
                    idProperty: myConfig.idProperty,
                    agregate,
                    lang: myConfig.lang,
                    promotionCode: myConfig.promotionCode,
                    includeFacilities: true,
                    mode: 'v4'
                };
                $http.get(staticHostUrl + "/api/availability", {params})
                    .success(function (res) {
                        if (agregate === 'PRODUCT') {
                            _.each(res, function (productAvailability) {
                                productAvailability.firstBookableDate = new Date(productAvailability.firstBookableDate);
                            });
                        }
                        if (agregate === 'ACCOMMODATION') {
                            _.each(res, function (productAvailability) {
                                _.each(productAvailability, function (pAvailability) {
                                    pAvailability.firstBookableDate = new Date(pAvailability.firstBookableDate);
                                });
                            });
                        }
                        cachedAvailability.resolve(res);
                        if (agregate === 'ACCOMMODATION') {
                            cachedAvailability = null;
                        }
                    })
                    .error(function (data, status) {
                        let errorMsg = '';
                        if (status !== 0 && data && data.errorMsg) {
                            errorMsg = data.errorMsg;
                        }
                        cachedAvailability.reject(errorMsg);
                        if (agregate === 'ACCOMMODATION') {
                            cachedAvailability = null;
                        }
                    });
                return cachedAvailability.promise;
            }

            function matchSelection(info, selection, idProduct) {
                if (!info) return false;
                const product = _.findWhere(info.products, {idProduct: parseInt(idProduct)});
                if (!product) return false;
                if (!selection) return true;

                if (selection.categoryGroupIds instanceof Array) {
                    if (!selection.categoryIds) selection.categoryIds = [];
                    _.each(info.categories, function (c) {
                        if (_.contains(selection.categoryGroupIds, c.idCategoryGroup)) {
                            selection.categoryIds.push(c.idCategory);
                        }
                    });
                }
                if (selection.categoryIds instanceof Array) {
                    const aInt = _.intersection(
                        _.map(product.categories, function (e) {
                            return parseInt(e, 10);
                        }),
                        _.map(selection.categoryIds, function (e) {
                            return parseInt(e, 10);
                        }));
                    if (aInt.length === 0) return false;
                }
                if (selection.facilityIds instanceof Array) {
                    if (_.intersection(product.facilities, selection.facilityIds).length < selection.facilityIds.length) return false;
                }
                return true;
            }

            function getPropertyAvailability(selection) {
                selection = _.cloneDeep(selection);
                let agregate = 'PRODUCT';
                return getInfo().then(function (info) {
                    if (Array.isArray(selection.facilityIds) && selection.facilityIds.length > 0) {
                        agregate = 'ACCOMMODATION';
                    }
                    return Promise.all([info, getAvailability(agregate)]);
                })
                    .then(function ([info, availability]) {

                        if (_.size(availability) === 0) {
                            return getAllAvailability();
                        }

                        if (selection.categoryIds.length === 0) {
                            _.each(info.categories, function (c) {
                                if (selection.categoryIds.indexOf(c.idCategory) === -1) selection.categoryIds.push(c.idCategory);
                            });
                        }

                        if (agregate === 'ACCOMMODATION') {
                            const newAvailability = {};
                            _.each(availability, function (productAvailability, idProduct) {
                                if (productAvailability.experience != null) {
                                    return;
                                }
                                let minAccommodationFirstBookableDate = null;
                                _.each(productAvailability, function (accommodationAvailability, accommodationKey) {
                                    const d = DU.date2int(new Date(accommodationAvailability.firstBookableDate));
                                    if ((minAccommodationFirstBookableDate === null) || (d < minAccommodationFirstBookableDate)) {
                                        minAccommodationFirstBookableDate = d;
                                    }
                                });
                                _.each(productAvailability, function (accommodationAvailability, accommodationKey) {
                                    if (selection.facilityIds.every((facilityId) => accommodationAvailability.facilities.includes(facilityId))) {
                                        if (newAvailability[idProduct] == null) {
                                            newAvailability[idProduct] = accommodationAvailability;
                                            newAvailability[idProduct].firstBookableDate = minAccommodationFirstBookableDate ? DU.int2date(minAccommodationFirstBookableDate) : DU.int2date(DU.today());
                                        } else {
                                            // concat facilites and clean duplicates with vanilla
                                            newAvailability[idProduct].facilities = [...new Set([...newAvailability[idProduct].facilities, ...accommodationAvailability.facilities])];
                                            const offset = DU.date2int(new Date(accommodationAvailability.firstBookableDate)) - minAccommodationFirstBookableDate;
                                            _.each(accommodationAvailability.matrix, function (val, idx) {
                                                newAvailability[idProduct].matrix[idx + offset] = newAvailability[idProduct].matrix[idx + offset] || 0;
                                                newAvailability[idProduct].matrix[idx + offset] = newAvailability[idProduct].matrix[idx + offset] | val;
                                            });
                                        }
                                    }
                                });
                            });
                            availability = newAvailability;
                        }

                        let minDateI = null;
                        _.each(availability, function (productAvailability, idProduct) {
                            if (!productAvailability) return;
                            if (!matchSelection(info, selection, idProduct)) return;
                            if (Array.isArray(selection.productIds) && !selection.productIds.includes(idProduct.toString())) {
                                return;
                            }
                            const d = DU.date2int(new Date(productAvailability.firstBookableDate));
                            if ((minDateI === null) || (d < minDateI)) minDateI = d;
                        });

                        const outMatrix = {
                            firstBookableDate: minDateI ? DU.int2date(minDateI) : DU.int2date(DU.today()),
                            matrix: [],
                            maxStay: 28
                        };

                        if (!minDateI) return getNoneAvailability();

                        _.each(availability, function (productAvailability, idProduct) {
                            if (!productAvailability) return;
                            if (!matchSelection(info, selection, idProduct)) return;
                            const offset = DU.date2int(new Date(productAvailability.firstBookableDate)) - minDateI;
                            if (productAvailability.maxStay > outMatrix.maxStay) {
                                outMatrix.maxStay = productAvailability.maxStay;
                            }
                            _.each(productAvailability.matrix, function (val, idx) {
                                outMatrix.matrix[idx + offset] = outMatrix.matrix[idx + offset] || 0;
                                outMatrix.matrix[idx + offset] = outMatrix.matrix[idx + offset] | val;
                            });
                        });

                        for (let i = 0; i < outMatrix.matrix.length - 1; i += 1) {
                            outMatrix.matrix[i] = outMatrix.matrix[i] || 0;
                        }

                        return outMatrix;
                    });
            }

            function getAllAvailability() {
                var cachedAllAvailability = $q.defer();
                getInfo().then(function (info) {
                    // const intOpenDate = Date.parse(info.openDate) / 86400000;
                    // const intCloseDate = Date.parse(info.closeDate) / 86400000;
                    const intToday = DU.date2int(new Date());
                    //var intFirstDay = intToday < intOpenDate ? intOpenDate : intToday;
                    const intFirstDay = intToday;
                    const availabilityObject = {
                        firstBookableDate: DU.int2date(intFirstDay),
                        matrix: []
                    };
                    //var days = intCloseDate - intFirstDay;
                    const days = 365;
                    for (let i = 0; i <= days - 1; i++) {
                        const exp = days - i < 28 ? days - i : 28;
                        availabilityObject.matrix[i] = Math.pow(2, exp) - 1;
                    }
                    cachedAllAvailability.resolve(availabilityObject);
                });
                return cachedAllAvailability.promise;
            }

            function getNoneAvailability() {
                var noneAvailability = $q.defer();
                getInfo().then(function (info) {
                    var intOpenDate = Date.parse(info.openDate) / 86400000;
                    var intCloseDate = Date.parse(info.closeDate) / 86400000;
                    var intToday = DU.date2int(new Date());
                    //var intFirstDay = intToday < intOpenDate ? intOpenDate : intToday;
                    var intFirstDay = intToday;
                    var availabilityObject = {
                        firstBookableDate: DU.int2date(intFirstDay),
                        matrix: [],
                        maxStay: 28
                    };
                    availabilityObject.matrix[0] = 0;
                    //var days = intCloseDate - intFirstDay;
                    noneAvailability.resolve(availabilityObject);
                });
                return noneAvailability.promise;
            }

            function calculateExpeditionDate(documentType, expirationDate, birthDate, countryIso) {

                let offset = 10;
                let expeditionDate = null;

                if (documentType != null && expirationDate != null && birthDate != null && countryIso != null) {

                    // const expirationDate = new Date(expirationDate);
                    let year = expirationDate.getFullYear();
                    let month = (expirationDate.getMonth() < 9) ? `0${expirationDate.getMonth() + 1}` : expirationDate.getMonth() + 1;
                    let day = (expirationDate.getDate() < 10) ? `0${expirationDate.getDate()}` : expirationDate.getDate();

                    const expirationTime = expirationDate.getTime();
                    //  birthDate = n.utc(birthDate);
                    const birthDateTime = birthDate.getTime();
                    const oneDay = 1000 * 60 * 60 * 24;

                    const daysFromBirth = (expirationTime - birthDateTime) / oneDay;

                    let {middleAge, oldAge} = countryAges.DEFAULT.diff;
                    let middleAgeOffset = countryAges.DEFAULT.offset.middleAge;
                    let oldAgeOffset = countryAges.DEFAULT.offset.oldAge;

                    if (countryAges[countryIso]) {
                        if (countryAges[countryIso].diff) {
                            middleAge = countryAges[countryIso].diff.middleAge;
                            oldAge = countryAges[countryIso].diff.oldAge;
                        }
                        if (countryAges[countryIso].offset) {
                            middleAgeOffset = countryAges[countryIso].offset.middleAge;
                            oldAgeOffset = countryAges[countryIso].offset.oldAge;
                        }
                        if (documentType && documentType === 'Pass') {
                            if (countryAges[countryIso].PassDiff) {
                                middleAge = countryAges[countryIso].PassDiff.middleAge;
                                oldAge = countryAges[countryIso].PassDiff.oldAge;
                            }
                            if (countryAges[countryIso].PassOffset) {
                                middleAgeOffset = countryAges[countryIso].PassDiff.middleAge;
                                oldAgeOffset = countryAges[countryIso].PassDiff.oldAge;
                            }
                        }
                    }

                    if (daysFromBirth < ((middleAge + middleAgeOffset) * 365)) {
                        offset = middleAgeOffset;
                    } else if (daysFromBirth < ((oldAge + oldAgeOffset) * 365)) {
                        offset = oldAgeOffset;
                    } else if (daysFromBirth >= ((oldAge + oldAgeOffset) * 365) && expirationDate === '9999-01-01T00:00:00.000Z') {
                        year = birthDate.getFullYear() + oldAge;
                        month = (birthDate.getMonth() < 9) ? `0${birthDate.getMonth() + 1}` : birthDate.getMonth() + 1;
                        day = (birthDate.getDate() < 10) ? `0${birthDate.getDate()}` : birthDate.getDate();
                    }

                    expeditionDate = new Date(`${year - offset}-${month}-${day}T00:00:00.000Z`);

                }

                return expeditionDate;
            }

            return {
                calculateExpeditionDate: calculateExpeditionDate,
                getInfo: getInfo,
                getIsoLanguages: function () {
                    return this.getInfo().then(function (info) {
                        const langs = [];
                        info.langs.forEach(function (lang) {
                            langs.push(lang.ISO);
                        });
                        return langs;
                    });
                },
                getPropertyAvailability: function () {
                    if (myConfig.searchOnlyAvailableDates) {
                        return getPropertyAvailability();
                    } else {
                        return getAllAvailability();
                    }
                },
                getSelectionAvailability: function (selection) {
                    if (myConfig.searchOnlyAvailableDates) {
                        return getPropertyAvailability(selection);
                    } else {
                        return getAllAvailability();
                    }
                },
                getAvailability,
                getProductAvailability: function (idProduct) {
                    return getAvailability().then(function (availability) {
                        if (availability[idProduct]) {
                            return availability[idProduct];
                        } else return {};
                    }, function () {
                        return {};
                    });
                },
                getBudgets: function (filterParams) {
                    const defered = $q.defer();
                    filterParams.promotionCode = filterParams.promotionCode || '';

                    if (!filterParams || !filterParams.checkin) {
                        defered.resolve([]);
                        return defered.promise;
                    }

                    $http.get(myConfig.host + "/api/budgets", {
                        params: Object.assign(filterParams, {idProperty: myConfig.idProperty})
                    })
                        .success(function (budgets) {
                            defered.resolve(budgets);
                        })
                        .error(function (data, status) {
                            if (data && data.errorCode == "input.invalidPromotionalCode") {
                                myConfig.promotionCode = '';
                            }
                            let errorMsg = '';
                            if ((status !== 0) && data) {
                                errorMsg = data.errorMsg;
                            }
                            defered.reject(errorMsg);
                        });
                    return defered.promise;
                },
                getExperiences: function (filterParams) {
                    const defered = $q.defer();
                    if (!filterParams) {
                        defered.resolve(cachedExperiences);
                        return defered.promise;
                    }
                    if (cachedExperiences.length) {
                        defered.resolve(cachedExperiences);
                        return defered.promise;
                    }
                    filterParams.promotionCode = filterParams.promotionCode || '';
                    $http.get(myConfig.host + "/api/experiences", {
                        params: Object.assign(filterParams, {idProperty: myConfig.idProperty})
                    })
                        .success(function (budgets) {
                            cachedExperiences = budgets;
                            defered.resolve(budgets);
                        })
                        .error(function (data, status) {
                            if (data && data.errorCode === "input.invalidPromotionalCode") {
                                myConfig.promotionCode = '';
                            }
                            let errorMsg = '';
                            if ((status !== 0) && data) {
                                errorMsg = data.errorMsg;
                            }
                            defered.reject(errorMsg);
                        });
                    return defered.promise;
                },
                getBookingForm: function (bookingFormParams) {

                    const normalizedBookingFormParams = {
                        idProperty: myConfig.idProperty,
                        idProduct: parseInt(bookingFormParams.idProduct, 10),
                        lang: myConfig.lang,
                        promotionCode: bookingFormParams.promotionCode || ""
                    };

                    if (bookingFormParams.checkin != null) {
                        normalizedBookingFormParams.checkin = bookingFormParams.checkin.toISOString().substr(0, 10);
                    }

                    if (bookingFormParams.checkout != null) {
                        normalizedBookingFormParams.checkout = bookingFormParams.checkout.toISOString().substr(0, 10);
                    }

                    if (bookingFormParams.guestAges != null) {
                        normalizedBookingFormParams.guestAges = bookingFormParams.guestAges.join(',');
                    }

                    if (bookingFormParams.facilityIds != null) {
                        normalizedBookingFormParams.facilityIds = bookingFormParams.facilityIds.join(',');
                    }

                    if (bookingFormParams.cryptoId != null) {
                        normalizedBookingFormParams.cryptoId = bookingFormParams.cryptoId;
                    }

                    if (bookingFormParams.accRef != null) {
                        normalizedBookingFormParams.accRef = bookingFormParams.accRef;
                    }

                    if (lastNormalizedBookingFormParams && lastNormalizedBookingFormParams === JSON.stringify(normalizedBookingFormParams)) {
                        return cachedBookingForm.promise;
                    } else {
                        cachedBookingForm = $q.defer();
                        lastNormalizedBookingFormParams = JSON.stringify(normalizedBookingFormParams);
                    }

                    if (bookingFormParams.idClientGroup != null) {
                        normalizedBookingFormParams.idClientGroup = bookingFormParams.idClientGroup;
                    }

                    if (bookingFormParams.email != null) {
                        normalizedBookingFormParams.email = bookingFormParams.email;
                    }

                    if (bookingFormParams.lastName != null) {
                        normalizedBookingFormParams.lastName = bookingFormParams.lastName;
                    }

                    $http.get(myConfig.host + "/api/bookingForm", {params: normalizedBookingFormParams})
                        .success(function (bookingForm) {
                            cachedBookingForm.resolve(bookingForm);
                        })
                        .error(function (data, status) {
                            if (data && data.errorCode == "input.invalidPromotionalCode") {
                                myConfig.promotionCode = "";
                            }
                            let errorMsg = '';
                            if ((status !== 0) && data) {
                                errorMsg = data.errorMsg;
                            }
                            cachedBookingForm.reject(errorMsg);
                        });
                    return cachedBookingForm.promise;
                },
                getCryptoTpv: function (signature, merchantParameters) {
                    const defered = $q.defer();

                    $http.post(myConfig.host + "/api/bookings/tpvIdBooking?idProperty=" + myConfig.idProperty, {
                        key: signature,
                        params: merchantParameters
                    })
                        .success(function (data) {
                            cachedBooking = $q.defer();
                            cachedBooking.resolve(data);
                            defered.resolve(data);
                        })
                        .error(function (data, status) {
                            var errorMsg = '';
                            if ((status !== 0) && data) {
                                errorMsg = data.errorMsg;
                            }
                            defered.reject(data);
                        });
                    return defered.promise;
                },
                postBooking: function (booking, tpv) {
                    const defered = $q.defer();

                    let bookingUrl = "/api/bookings?idProperty=";
                    if (tpv) {
                        bookingUrl = "/api/bookings/tpv?idProperty=";
                    }
                    $http.post(myConfig.host + bookingUrl + myConfig.idProperty, booking)
                        .success(function (data) {
                            cachedBooking = $q.defer();
                            cachedBooking.resolve(data);
                            defered.resolve(data);
                        })
                        .error(function (data, status) {
                            var errorMsg = '';
                            if ((status !== 0) && data) {
                                errorMsg = data.errorMsg;
                            }
                            defered.reject(data);
                        });
                    return defered.promise;
                },
                getDoc: function (template, idBooking) {
                    const defered = $q.defer();

                    $http.get(myConfig.host + "/api/docs/" + template + "/" + idBooking)
                        .success(function (data) {
                            cachedDoc = $q.defer();
                            cachedDoc.resolve(data);
                            defered.resolve(data);
                        })
                        .error(function (data, status) {
                            var errorMsg = '';
                            if ((status !== 0) && data) {
                                errorMsg = data.errorMsg;
                            }
                            defered.reject(data);
                        });
                    return defered.promise;
                },
                getBooking: function (cryptedIdBooking) {
                    if (cachedBooking) return cachedBooking.promise;
                    cachedBooking = $q.defer();
                    $http.get(myConfig.host + "/api/bookings", {
                        params: {
                            cryptedIdBooking: cryptedIdBooking,
                            idProperty: myConfig.idProperty
                        }
                    })
                        .success(function (bookingSuccess) {
                            cachedBooking.resolve(bookingSuccess);
                        })
                        .error(function (data, status) {
                            let errorMsg = '';
                            if (status !== 0 && data) {
                                errorMsg = data.errorMsg;
                            }
                            cachedBooking.reject(errorMsg);
                        });
                    return cachedBooking.promise;
                },
                getPaymentLink: function (params) {
                    // if (cachedBooking) return cachedBooking.promise;
                    cachedBooking = $q.defer();
                    $http
                        .get(myConfig.host + "/api/payment", {
                            params: Object.assign({}, params, {idProperty: myConfig.idProperty})
                        })
                        .success(function (bookingSuccess) {
                            cachedBooking.resolve(bookingSuccess);
                        })
                        .error(function (data, status) {
                            let errorMsg = '';
                            if (status !== 0 && data) {
                                errorMsg = data.errorMsg;
                            }
                            cachedBooking.reject(errorMsg);
                        });
                    return cachedBooking.promise;
                },
                clearInfoCache: function () {
                    cachedInfo = null;
                    return;
                },
                getPrice: function (data) {

                    const {
                        checkin,
                        checkout,
                        guestAges,
                        showBasePriceDetail,
                        baseName,
                        sectionGroups,
                        additionalConcepts,
                        basePrice,
                        gettextCatalog
                    } = data;

                    function addConceptGroupToGroup(group, conceptGroup, q) {
                        if (typeof q === "undefined") {
                            q = 1;
                        }
                        _.each(conceptGroup.lines, function (l) {
                            if (['AGREGATOR', 'VATINCLUDED', 'VAT'].includes(l.class)) {
                                return;
                            }
                            const newL = _.clone(l);
                            newL.quantity = l.quantity * q;
                            if (!l.attributes) {
                                newL.attributes = [];
                            }
                            if (!_.contains(newL.attributes, group)) {
                                newL.attributes.push(group);
                            }
                            budget.addPrice(newL);
                        });
                    }

                    const budget = new masterUtils.Price2({
                        reservation: DU.int2date(DU.today()),
                        checkin,
                        checkout,
                        guestAges
                    });

                    budget.addPrice({
                        id: "base",
                        class: "AGREGATOR",
                        label: baseName,
                        periods: DU.date2int(new Date(checkout)) - DU.date2int(new Date(checkin)),
                        quantity: 1,
                        showIfZero: true,
                        hideDetail: !showBasePriceDetail,
                        groupBy: "_RSV4-BASE",
                        execOrder: 40,
                        expanded: showBasePriceDetail == -2,
                        collapsable: true,
                        hideIfNoChilds: true,
                        order: 1
                    });

                    budget.addPrice({
                        id: "others",
                        class: "AGREGATOR",
                        label: 'Others',
                        hideTotal: true,
                        collapsable: false,
                        expanded: true,
                        groupBy: [
                            ["GUARANTEE"], ["FINANCE"], ["RESERVATIONFEE"],
                            ["ACCOMMODATIONCHECK"], ["OTHER"]
                        ],
                        execOrder: 35,
                        order: 1.5
                    });

                    budget.addPrice({
                        id: "tTax",
                        class: "AGREGATOR",
                        label: gettextCatalog.getString('Touristic Tax'),

                        ifOneHideParent: true,
                        collapsable: true,
                        expanded: true,

                        groupBy: "TURISTICTAX",
                        execOrder: 30,

                        order: 3
                    });

                    budget.addPrice({
                        id: "insurance",
                        class: "AGREGATOR",
                        label: gettextCatalog.getString('Insurance'),

                        hideTotal: true,
                        expanded: true,
                        collapsable: false,

                        groupBy: "INSURANCE",
                        execOrder: 20,

                        order: 4
                    });

                    budget.addPrice({
                        id: "discount",
                        class: "AGREGATOR",
                        label: gettextCatalog.getString('Discounts'),

                        hideTotal: true,
                        expanded: true,
                        collapsable: true,

                        groupBy: ["DISCOUNT", "EXPLICIT"],
                        execOrder: 10,

                        order: 5
                    });

                    if (myConfig.vatIncluded) {
                        budget.addPrice({
                            class: "VATINCLUDED"
                        });
                    } else {
                        budget.addPrice({
                            class: "VAT"
                        });
                    }

                    addConceptGroupToGroup("_RSV4-BASE", basePrice);

                    let iSectionGroup = 1;
                    _.each(sectionGroups, function (sg) {
                        const sectionGroupFlag = "_RSV4_SECTION_GROUP_" + sg.idSectionGroup;
                        budget.addPrice({
                            id: "sectionGroup-" + sg.idSectionGroup,
                            class: "AGREGATOR",
                            label: sg.title,

                            // ifOneHideParent: true,
                            expanded: true,
                            collapsable: true,

                            groupBy: sectionGroupFlag,
                            execOrder: 60,
                            hideIfNoChilds: true,
                            showIfZero: true,

                            attributes: ["_RSV4_SECTION_GROUP"],

                            order: 2
                        });
                        iSectionGroup += 1;
                        let iSection = 0;
                        _.each(sg.sections, function (section) {
                            _.each(section.additionalConcepts, function (ac) {
                                iSection += 1;
                                const additionalConceptFlag = "_RSV4_ADDITIONAL_CONCEPT_" + ac.idAdditionalConcept;
                                const conceptLine = {
                                    id: "additionalConcept-" + ac.idAdditionalConcept,
                                    class: "AGREGATOR",
                                    label: ac.label,

                                    ifOneHideParent: true,
                                    expanded: ac.showPriceDetail == -2,
                                    collapsable: true,
                                    hideDetail: !ac.showPriceDetail,
                                    hideIfNoChilds: true,
                                    showIfZero: true,

                                    groupBy: additionalConceptFlag,
                                    execOrder: 50,

                                    attributes: [sectionGroupFlag],

                                    order: iSection
                                };
                                let val = additionalConcepts[ac.idAdditionalConcept];
                                if (ac.type === "BOOLEAN") {
                                    if (val) {
                                        addConceptGroupToGroup(additionalConceptFlag, ac.unitPrice);
                                    }
                                }
                                if (ac.type === "QUANTITY") {
                                    val = parseInt(val, 10) || 0;
                                    // if (val < ac.min) val = ac.min;
                                    // if (val > ac.max) val = ac.max;
                                    if (val <= 0) return;
                                    const up = new masterUtils.Price2(ac.unitPrice);
                                    if (myConfig.vatIncluded) {
                                        up.addPrice({
                                            class: "VATINCLUDED"
                                        });
                                    }
                                    addConceptGroupToGroup(additionalConceptFlag, ac.unitPrice, val);
                                    conceptLine.quantity = val;
                                    conceptLine.price = up.getImport();
                                }
                                if (ac.type === "SINGLE_CHOICE") {
                                    if ((val) && (ac.options[val])) {
                                        addConceptGroupToGroup(additionalConceptFlag, ac.options[val].price);
                                    }
                                }
                                budget.addPrice(conceptLine);
                            });
                        });
                    });

                    return budget;
                },
                processGtmProduct: function (product, index = null, replaceDate = {}) {

                    const categories = cachedInfoData.categories;

                    let productCategories2 = null;
                    let productCategories3 = null;

                    for (const idCategory of product.categories || []) {
                        const category = categories.find(category => category.idCategory.toString() === idCategory);
                        const idCategoryInt = parseInt(idCategory, 10);
                        const categoryName = cachedTranslations.categories[idCategoryInt] || `Category ${idCategoryInt}`;
                        if (categoryName != null) {
                            if (productCategories3 == null) {
                                productCategories3 = categoryName;
                            } else if (!productCategories3.includes(categoryName)) {
                                productCategories3 += ` / ${categoryName}`;
                            }
                            if (category != null && category.idCategoryGroup != null) {
                                const idCategoryGroupInt = parseInt(category.idCategoryGroup, 10);
                                const categoryGroupName = cachedTranslations.categoryGroups[idCategoryGroupInt] || `Category Group ${idCategoryGroupInt}`;
                                if (productCategories2 == null) {
                                    productCategories2 = categoryGroupName;
                                } else if (!productCategories2.includes(categoryGroupName)) {
                                    productCategories2 += ` / ${categoryGroupName}`;
                                }
                            }
                        }
                    }
                    if (productCategories2 == null) {
                        productCategories2 = `Without Category Group`;
                    }
                    if (productCategories3 == null) {
                        productCategories3 = `Without Category`;
                    }

                    let productCategories4 = null;
                    const selectedFacilities = product.facilityIds != null ?
                        typeof product.facilityIds === 'string' ?
                            product.facilityIds.split(',') : product.facilityIds : [];
                    for (const idFacility of selectedFacilities || []) {
                        const facilityIdInt = parseInt(idFacility, 10);
                        const facilityName = cachedTranslations.facilities[facilityIdInt] || `Facility ${facilityIdInt}`;
                        if (productCategories4 == null) {
                            productCategories4 = facilityName;
                        } else if (!productCategories4.includes(facilityName)) {
                            productCategories4 += ` / ${facilityName}`;
                        }
                    }
                    if (productCategories4 == null) {
                        productCategories4 = `Without Facilities`;
                    }

                    let productGroupName = `Without Product Group`;
                    if (product.idProductGroup != null) {
                        const idProductGroupInt = parseInt(product.idProductGroup, 10);
                        productGroupName = cachedTranslations.productGroups[idProductGroupInt] || `Product Group ${product.idProductGroup}`;
                    }

                    const productIdInt = parseInt(product.idProduct, 10);
                    const productName = cachedTranslations.products[productIdInt] || `Product ${productIdInt}`;

                    let item_list_id = product.experience ? 'experiences' : (product.notAvailable ? 'without_availability' : 'with_availability');
                    let item_list_name = product.experience ? 'Experiences' : (product.notAvailable ? 'Without availability' : 'With availability');

                    const gtmProduct = {
                        id: product.idProduct,
                        name: productName,
                        sku: product.idProduct,
                        // coupon: product.promotionTitle,

                        item_id: product.idProduct,
                        item_name: productName,
                        affiliation: cachedInfoData.name,
                        currency: 'EUR',
                        discount: product.discount,
                        index,
                        item_brand: productGroupName,
                        item_category: product.experience ? 'Experience' : 'Booking',
                        item_category2: productCategories2,
                        item_category3: productCategories3,
                        item_category4: productCategories4,
                        item_list_id,
                        item_list_name,
                        price: !product.experience ? product.totalPrice - product.discount : null,
                        quantity: 1,
                        // Other params
                        item_brand_id: product.idWebVisualizationGroup
                    };

                    if (product.promotionCode != null) {
                        gtmProduct.promotion_id = product.promotionCode;
                        gtmProduct.promotion_name = cachedTranslations.promotionName;
                    }

                    Object.keys(replaceDate).forEach(key => {
                        if (replaceDate[key] != null) {
                            gtmProduct[key] = replaceDate[key];
                        }
                    });
                    if (typeof gtmProduct.price === 'string') {
                        gtmProduct.price = parseFloat(gtmProduct.price);
                    }
                    if (typeof gtmProduct.discount === 'string') {
                        gtmProduct.discount = parseFloat(gtmProduct.discount);
                    }
                    if (typeof gtmProduct.tax === 'string') {
                        gtmProduct.tax = parseFloat(gtmProduct.tax);
                    }
                    return gtmProduct;
                },
                getReservationData: function (cryptedIdBooking) {
                    return $http.get(myConfig.host + "/api/reservation", {
                        params: {
                            cryptedIdBooking: cryptedIdBooking,
                        }
                    })
                        .then(function (response) {
                            if (response.status === 200) {
                                return response.data;
                            } else {
                                return null;
                            }
                        });
                }
            };
        }]);
