/*jslint node: true */
"use strict";
/*globals angular, document, ga, window, fbq */

angular
    .module('payment_link', [])
    .config(PaymentLinkConfig)
    .controller('PaymentLinkCtrl', PaymentLinkCtrl);

PaymentLinkConfig.$inject = ['$stateProvider'];

function PaymentLinkConfig($stateProvider) {
    $stateProvider.state('payment_link', {
        url: '/payment/:encryptedQuery?payment?',
        views: {
            'content': {
                templateUrl: 'templates/payment_link.html',
                controller: 'PaymentLinkCtrl'
            }
        }
    });
}

PaymentLinkCtrl.$inject = ['$scope', 'Bookings', '$window', '$state', 'myConfig', 'alertsService', 'gettextCatalog', 'loadingService'];

function PaymentLinkCtrl($scope, Bookings, $window, $state, myConfig, alertsService, gettextCatalog, loadingService) {

    $scope.paymentData = {};

    loadingService.enable('principal', gettextCatalog.getString('Loading'), true, 0);

    $scope.payGuarantees = function () {
        loadData(() => {
            /** Construccion del form al tpv */
            var formTpv = document.createElement("form");
            formTpv.setAttribute('method', "post");
            // formTpv.setAttribute('target',"_blank");
            formTpv.setAttribute('action', $scope.paymentData.uri);

            var inpMarchantParameters = document.createElement("input");
            inpMarchantParameters.setAttribute('type', "hidden");
            inpMarchantParameters.setAttribute('name', "Ds_MerchantParameters");
            inpMarchantParameters.setAttribute('value', $scope.paymentData.params.Ds_MerchantParameters);

            var inpSignature = document.createElement("input");
            inpSignature.setAttribute('type', "hidden");
            inpSignature.setAttribute('name', "Ds_Signature");
            inpSignature.setAttribute('value', $scope.paymentData.params.Ds_Signature);

            var inpSignatureVersion = document.createElement("input");
            inpSignatureVersion.setAttribute('type', "hidden");
            inpSignatureVersion.setAttribute('name', "Ds_SignatureVersion");
            inpSignatureVersion.setAttribute('value', $scope.paymentData.params.Ds_SignatureVersion);

            formTpv.appendChild(inpMarchantParameters);
            formTpv.appendChild(inpSignature);
            formTpv.appendChild(inpSignatureVersion);
            document.getElementById('hiddenTPV').appendChild(formTpv);
            formTpv.submit();
        }, true);
    };

    function loadData(callback, includeSignature) {
        $scope.loading = true;
        alertsService.reset();
        Bookings.getPaymentLink(Object.assign({}, $state.params, {includeSignature}))
            .then(function (paymentData) {
                $scope.paymentData = paymentData;
                $scope.loading = false;
                if ($scope.paymentData.guarantee && $scope.paymentData.guarantee.payed) {
                    alertsService.new("paymentLink", "success", gettextCatalog.getString("The guarantee is already paid."));
                    if ($scope.paymentData.insurance && !$scope.paymentData.insurance.payed) {
                        alertsService.new("paymentLink", "warning", gettextCatalog.getString("You cannot proceed to a payment with paid lines."));
                    }
                }
                if ($scope.paymentData.insurance && $scope.paymentData.insurance.payed) {
                    alertsService.new("paymentLink", "success", gettextCatalog.getString("The insurance is already paid."));
                    if ($scope.paymentData.guarantee && !$scope.paymentData.guarantee.payed) {
                        alertsService.new("paymentLink", "warning", gettextCatalog.getString("You cannot proceed to a payment with paid lines."));
                    }
                }
                if (
                    ($scope.paymentData.guarantee && $scope.paymentData.guarantee.proforma) ||
                    ($scope.paymentData.insurance && $scope.paymentData.insurance.proforma)
                ) {
                    alertsService.new("paymentLink", "warning", gettextCatalog.getString("Your payment is being verified, plrase try again later."));
                }
                loadingService.disable('principal');
                if (callback) {
                    callback();
                }
            }, (err) => {
                loadingService.disable('principal');
                alertsService.new('init', "error", err || 'Unexpected error!');
            });
    }

    loadData();

}
