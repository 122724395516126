/*jslint node: true */
"use strict";
/*globals angular, masterUtils */

angular.module('filter_params_store', [])
    .factory('FilterParamsStore', ['$http', '$q', 'myConfig',
        function ($http, $q, myConfig) {

            const DU = masterUtils.dateUtils;

            let cachedFilterParams = {
                checkin: null,
                checkout: null,
                categoryIds: [],
                facilityIds: [],
                guestAges: [18, 18],
                promotionCode: null,
                ra: null,
                trackref: null,
                guid: null,
                origin: null
            };

            return {
                loadFormFilterParams: function () {
                    return cachedFilterParams;
                },
                saveFilterParams: function (newFilterParams) {
                    let tempFilterParams = cachedFilterParams;
                    if (newFilterParams.checkin) {
                        tempFilterParams.checkin = DU.date2int(new Date(newFilterParams.checkin + "T00:00:00.000Z"));
                    }
                    if (newFilterParams.checkout) {
                        tempFilterParams.checkout = DU.date2int(new Date(newFilterParams.checkout + "T00:00:00.000Z"));
                    }
                    if (newFilterParams.categoryIds) {
                        tempFilterParams.categoryIds = newFilterParams.categoryIds.split(',');
                            // .map((m) => parseInt(m));
                    }
                    if (newFilterParams.facilityIds) {
                        tempFilterParams.facilityIds = newFilterParams.facilityIds.split(',');
                            // .map((m) => parseInt(m));
                    }
                    if (newFilterParams.guestAges) {
                        tempFilterParams.guestAges = newFilterParams.guestAges.split(',');
                    }
                    if (newFilterParams.promotionCode) {
                        tempFilterParams.promotionCode = newFilterParams.promotionCode;
                    }
                    if (newFilterParams.ra) {
                        tempFilterParams.ra = newFilterParams.ra;
                    }
                    if (newFilterParams.trackref) {
                        tempFilterParams.trackref = newFilterParams.trackref;
                    }
                    if (newFilterParams.guid) {
                        tempFilterParams.guid = newFilterParams.guid;
                    }
                    if (newFilterParams.origin) {
                        tempFilterParams.origin = newFilterParams.origin;
                    }
                    if (newFilterParams.experiences) {
                        tempFilterParams.experiences = newFilterParams.experiences === 'true' || newFilterParams.experiences === true;
                    }
                    if (tempFilterParams !== cachedFilterParams) {
                        angular.copy(tempFilterParams, cachedFilterParams);
                    }
                }
            };
        }]);
