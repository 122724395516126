/*jslint node: true */
"use strict";
/*globals mod,masterUtils,_, angular, window, MasterWidget, document, globalConfig */

angular.module('calendar_widget', [])
    .directive('calendarWidget', ['myConfig', 'gettextCatalog', 'Bookings',
        function (myConfig, gettextCatalog, Bookings) {
            return {
                templateUrl: function () {
                    const template = 'templates/calendar_widget.html';
                    return globalConfig.production ?
                        `${globalConfig.middlewareHost}/${globalConfig.rootName}/${template}?version=${globalConfig.version}` :
                        template;
                },
                scope: {
                    product: '=',
                    onDatesChanged: "="
                },
                restrict: 'E',
                link: function ($scope, element) {

                    const eDates = element.children()[0];
                    const wDates = new MasterWidget.StayDates(eDates, {
                        lang: myConfig.lang
                    });

                    function protect(f) {
                        const phase = $scope.$root.$$phase;
                        if (phase == '$apply' || phase == '$digest') {
                            f();
                        } else {
                            $scope.$apply(f);
                        }
                    }

                    wDates.onCheckinDate = function (newDate) {
                        protect(function () {
                            $scope.product.checkin = newDate;
                            if ($scope.product.checkout == "Invalid Date") {
                                delete $scope.selection.checkout;
                            }
                            if ($scope.product.disableCheckOut !== true && $scope.product.checkout == null) {
                                wDates.checkOut.showPopup();
                            }
                        });
                    };

                    wDates.onCheckoutDate = function (newDate) {
                        protect(function () {
                            $scope.product.checkout = newDate;
                        });
                    };

                    function updateProduct() {
                        if ($scope.product.checkin != null) {
                            const checkIn = typeof $scope.product.checkin === 'string' ?
                                new Date($scope.product.checkin) : $scope.product.checkin;
                            wDates.checkIn.setSelDate(checkIn);
                        }

                        if ($scope.product.checkout != null) {
                            const checkOut = typeof $scope.product.checkout === 'string' ?
                                new Date($scope.product.checkout) : $scope.product.checkout;
                            wDates.checkOut.setSelDate(checkOut);
                        }

                        if ($scope.product) {
                            if ($scope.product.availability != null) {
                                wDates.setAvailability($scope.product.availability);
                            } else {
                                Bookings.getProductAvailability($scope.product.idProduct).then(function (availability) {
                                    wDates.setAvailability(availability);
                                });
                            }
                        } else {
                            wDates.setAvailability(null);
                        }
                    }

                    updateProduct();

                    $scope.$watch('product.checkin', function () {
                        const checkIn = typeof $scope.product.checkin === 'string' ?
                            new Date($scope.product.checkin) : $scope.product.checkin;
                        if ($scope.product.checkout === null) {
                            wDates.checkIn.setSelDate(checkIn);
                        } else {
                            updatePrice();
                        }
                        // hideCheckOut();
                    }, true);

                    const DU = masterUtils.dateUtils;

                    function updatePrice() {
                        if ($scope.product.experience) {
                            return;
                        }
                        if ($scope.product.checkout === undefined || $scope.product.checkout === null || (Object.prototype.toString.call($scope.product.checkout) === "[object Date]" && isNaN($scope.product.checkout.getTime()))) {
                            $scope.product.checkout = null;
                            delete $scope.product.totalPrice;
                            delete $scope.product.pricePerDay;
                            delete $scope.product.pricePerPersonPerDay;
                            delete $scope.product.pricePerPerson;
                            delete $scope.product.promotionTitle;
                            delete $scope.product.promotionDescription;
                            delete $scope.product.promotionCode;
                            return;
                        }

                        const checkin = typeof $scope.product.checkin === 'string' ?
                            $scope.product.checkin : $scope.product.checkin.toISOString().substring(0, 10);
                        const checkout = typeof $scope.product.checkout === 'string' ?
                            $scope.product.checkout : $scope.product.checkout.toISOString().substring(0, 10);

                        const params = {
                            productIds: $scope.product.idProduct,
                            checkin,
                            checkout,
                            guestAges: $scope.product.guestAges,
                            idProperty: myConfig.idProperty,
                            lang: myConfig.lang,
                            promotionCode: $scope.product.constPromotionCode
                        };

                        delete $scope.product.notAvailable;
                        $scope.product.loading = true;

                        if ($scope.product.accRef != null && $scope.product.accRef !== "") {
                            params.roomIds = $scope.product.accRef;
                        }

                        Bookings.getBudgets(params).then(function (response) {
                            const nPeriods = DU.date2int($scope.product.checkout) - DU.date2int($scope.product.checkin);
                            const nPersons = $scope.product.guestAges.split(",").length;
                            if (response.budgets && response.budgets.length > 0) {
                                $scope.product.totalPrice = response.budgets[0].totalPrice;
                                $scope.product.pricePerDay = response.budgets[0].totalPrice / nPeriods;
                                $scope.product.pricePerPersonPerDay = response.budgets[0].totalPrice / nPeriods / nPersons;
                                $scope.product.pricePerPerson = response.budgets[0].totalPrice / nPersons;
                                $scope.product.discount = response.budgets[0].discount;
                                $scope.product.promotionTitle = response.budgets[0].promotionTitle;
                                $scope.product.promotionDescription = response.budgets[0].promotionDescription;
                                $scope.product.promotionCode = response.budgets[0].promotionCode;
                                $scope.product.totalWithoutDiscount = $scope.product.totalPrice - $scope.product.discount;
                                $scope.product.percentageDiscount = Math.floor(Math.abs($scope.product.discount) / $scope.product.totalWithoutDiscount * 100);
                            } else {
                                $scope.product.notAvailable = true;
                            }
                            delete $scope.product.loading;
                            if ($scope.onDatesChanged) {
                                $scope.onDatesChanged();
                            }
                        }, function () {
                            delete $scope.product.loading;
                        });
                    }

                    $scope.$watch('product.checkout', updatePrice, true);

                    // function hideCheckOut() {
                    //     $timeout(function () {
                    //         if ($scope.product.disableCheckOut) {
                    //             const checkOutContainer = element.find('.checkoutContainer');
                    //             checkOutContainer.css('display', 'none');
                    //         }
                    //     }, 100);
                    // }
                    //
                    // hideCheckOut();

                }
            };
        }]);
