/*jslint node: true */
"use strict";
/*globals mod, masterUtils, _, angular, window, navigator, $ */

angular
    .module('search_filter_param', [])
    .controller('FilterParamsCtrl', [
        '$scope', 'gettextCatalog', 'alertsService', '$location', '$window', 'myConfig',
        function ($scope, gettextCatalog, alertsService, $location, $window, myConfig) {

            $scope.myConfig = myConfig;

            $scope.filterParams.guestAges.forEach(function (ga) {
                if (ga >= 0 && ga < 18) {
                    $scope.guests.children++;
                    $scope.guests.childrenAges.push(parseInt(ga));
                }
                if (ga >= 18) {
                    $scope.guests.adults++;
                }
            });

            $scope.submitSearchForm = function () {
                $scope.queryPending = true;
                if (!$scope.searching) {
                    $scope.resetResults();
                    alertsService.reset();
                    if ($scope.filterParams.experiences !== true) {
                        $scope.doSearch();
                    } else {
                        $scope.showExperiences();
                    }
                }
            };

            $scope.toggleExperiences = function () {
                $scope.filterParams.categoryIds = [];
                $scope.filterParams.experiences = !$scope.filterParams.experiences;
                if ($scope.filterParams.experiences !== true) {
                    $scope.submitSearchForm();
                }
            };

            $scope.getButtonName = function () {
                return $scope.filterParams.experiences ?
                    gettextCatalog.getString('Show experiences') :
                    gettextCatalog.getString('Show Prices and availability');
            };

            $scope.getExperiencesText = function () {
                let experiencesSelectorText = gettextCatalog.getString('Experiences');
                if ($scope.info.selectorMode &&
                    $scope.info.selectorMode.experiencesText != null &&
                    $scope.info.selectorMode.experiencesText[$scope.lang] != null) {
                    experiencesSelectorText = $scope.info.selectorMode.experiencesText[$scope.lang];
                }
                return experiencesSelectorText;
            };

            $scope.getStaysText = function () {
                let selectorStaysText = gettextCatalog.getString('Stays');
                if ($scope.info.selectorMode &&
                    $scope.info.selectorMode.staysText != null &&
                    $scope.info.selectorMode.staysText[$scope.lang] != null) {
                    selectorStaysText = $scope.info.selectorMode.staysText[$scope.lang];
                }
                return selectorStaysText;
            };

            $scope.applyPromotionCode = function () {
                $location.search('promotionCode', $scope.filterParams.promotionCode).replace();
                setTimeout(() => {
                    $window.location.reload();
                }, 1000);
            };

            $scope.disablePromotionCode = function () {
                $scope.filterParams.promotionCode = null;
                $location.search('promotionCode', null).replace();
                $location.search('disableAutomaticPromotion', true).replace();
                $location.search('categoryIds', null).replace();
                setTimeout(() => {
                    $window.location.reload();
                }, 1000);
            };

            $scope.showApplyPromotionButton = function () {
                if ($scope.filterParams.promotionCode == null || $scope.filterParams.promotionCode === '') {
                    return false;
                }
                return $scope.filterParams.promotionCode !== $location.search().promotionCode;
            };

            $scope.disablePromotionCodeInput = function() {
                if ($scope.filterParams.promotionCode == null || $scope.filterParams.promotionCode === '') {
                    return false;
                }
                return $scope.filterParams.promotionCode !== $location.search().promotionCode;
            };

            $scope.keyUp = function($event) {
                if ($event.which === 13) {
                    $scope.applyPromotionCode();
                }
            };

            $scope.showDeletePromotionButton = function () {
                return $scope.filterParams.promotionCode != null &&
                    $scope.filterParams.promotionCode !== '' &&
                    $scope.filterParams.promotionCode === $location.search().promotionCode;
            };

        }]);
