/*jslint node: true */
"use strict";
/*globals masterUtils, _, angular, window, navigator, ga, document, ma_ut, smartlook, fbq, gtag */

const globalConfig = {
    //CLIENT_CONF
};

angular.module('app', ['ui.router', 'gettext', 'ngDialog', 'ngSanitize', 'ngCookies', //MODULES

        // 'sceTrust', // TODO NO EXISTE
        // 'price_viewer', // TODO NO SE USA

    ])
    .constant('myConfig', globalConfig)
    .config(configApp)
    .run(runApp);

configApp.$inject = ['$httpProvider', '$urlRouterProvider', '$locationProvider', '$sceDelegateProvider'];

function configApp($httpProvider, $urlRouterProvider, $locationProvider, $sceDelegateProvider) {

        $sceDelegateProvider.resourceUrlWhitelist([
            'self',
            `${globalConfig.middlewareHost}/**`
        ]);

    $urlRouterProvider.otherwise('/search');

    $locationProvider.html5Mode(true);

    delete $httpProvider.defaults.headers.common['Cache-Control'];
    delete $httpProvider.defaults.headers.common.Pragma;

    $httpProvider.interceptors.push(["$q", "$injector",
        function ($q, $injector) {
            return {
                'request': function (config) {
                    delete config.headers['Cache-Control'];
                    delete config.headers.Pragma;
                    return config;
                },
                'responseError': function (rejection) {
                    if ($injector.get('$state').current.name !== "checkin") {
                        if (rejection.config.url.indexOf("bookingForm") !== -1) {
                            $injector.get('alertsService').new('bookingForm', 'error', rejection.data.message || rejection.data.errorMsg);
                            if ($injector.get('$state').current.name === "") {
                                $injector.get('$state').go('search', rejection.config.params);
                            }
                        }
                    }

                    if (rejection.status === 500) {
                        if (rejection.data.code == "middleware.offline") {
                            $injector.get('alertsService').new("infoReqSuccess", "error", "Sorry, there has been an error in the service.");
                            $injector.get('$state').go('inforeq');
                        }
                        if (rejection.data.code == "makeBooking.ETIMEDOUT") {
                            $injector.get('alertsService').new("infoReqSuccess", "error", "Sorry, there has been an error in the service.");
                            $injector.get('$state').go('inforeq');
                        }
                    }

                    return $q.reject(rejection);
                }
            };
        }]);

}

runApp.$inject = ['$rootScope', 'gettextCatalog', '$location', '$window', 'myConfig', '$sce'];

function runApp($rootScope, gettextCatalog, $location, $window, myConfig, $sce) {
    $rootScope.availableLangs = [];

    var state = "search";

    $rootScope.$on('$stateChangeSuccess',
        function (event, toState, toParams, fromState, fromParams) {
            if (myConfig.trackingId && toState.name != "booking") {
                var tmpPath = "/" + toState.name;
                var keys = Object.keys(toParams);
                var firstParamIncluded = false;
                keys.forEach(function (key) {
                    if (!firstParamIncluded) {
                        tmpPath += "?";
                        if (toParams[key]) {
                            tmpPath += key + "=" + toParams[key];
                        }
                        firstParamIncluded = true;
                    } else {
                        if (toParams[key]) {
                            tmpPath += "&" + key + "=" + toParams[key];
                        }
                    }
                });

                if (myConfig.trackingId && typeof ga !== "undefined") {
                    ga('send', 'pageview', {page: tmpPath});
                }

                if (myConfig.googleGlobalTag && typeof gtag !== "undefined") {
                    gtag('event', 'page_view', {page: tmpPath});
                }

                if (typeof ma_ut !== "undefined"){
                    ma_ut.post({
                        action: "pageview",
                        page: tmpPath
                    });
                }
            }

            if (toState.name === "checkin"){
                if(window._loq) {
                    window._loq.push(["tag", "ExpressCheckin"]);
                }

                if(typeof smartlook === "function") {
                    smartlook('tag', 'Page', 'ExpressCheckin');
                }
            }

            state = toState.name;
            $rootScope.currentState = toState.name;
            $rootScope.currentStateCss = toState.name;
            $rootScope.lastState = fromState.name;
            $rootScope.lastStateParams = fromParams;
        });

    $rootScope.getArray = function (num) {
        const tmpArray = [];
        for (let i=0; i<num; i++){
            tmpArray.push(i);
        }
        return tmpArray;
    };

    $rootScope.getImageUrl = function (imageObject, principal, size) {
        let idImage;
        if (principal) {
            if (imageObject.principal) {
                idImage = imageObject.principal.idImage;
            } else {
                if (imageObject.gallery && imageObject.gallery.length > 0) {
                    idImage = imageObject.gallery[0].idImage;
                }
            }
        } else {
            idImage = imageObject;
        }

        const staticHostUrl = myConfig.production ? myConfig.middlewareHost : myConfig.host;
        let imageUrl = staticHostUrl + '/images/' + myConfig.idProperty + '/' + idImage;
        if (size) {
            imageUrl = imageUrl + "_" + size;
        }
        if (idImage === undefined) {
            imageUrl = `${staticHostUrl}/${myConfig.rootName}/static/img/no_image.png`;
        }
        return imageUrl;
    };

    function selectLanguage() {
        let selectedLang = myConfig.lang;

        if (navigator.language) {
            selectedLang = navigator.language.substring(0, 2);
        }

        if ($location.search().lang) {
            selectedLang = $location.search().lang;
        }

        if (myConfig.availableLangs.length > 0 && myConfig.availableLangs.indexOf(selectedLang) === -1) {
            selectedLang = 'en';
        }

        gettextCatalog.setCurrentLanguage(selectedLang);

        const translationPath = myConfig.production ?
            `${myConfig.middlewareHost}/${myConfig.rootName}/translations/${selectedLang}.json?version=${myConfig.version}` :
            `translations/${selectedLang}.json`;
        // gettextCatalog.loadRemote("/translations/" + selectedLang + ".json");
        // gettextCatalog.loadRemote($sce.trustAsResourceUrl(translationPath));
        gettextCatalog.loadRemote(translationPath);

        if (myConfig.development) {
            gettextCatalog.debug = false;
        }

        const tmpPath = $location.path();
        const tmpSearch = $location.search();
        tmpSearch.lang = selectedLang;
        $location.path(tmpPath).search(tmpSearch).replace();

        myConfig.lang = selectedLang;

        if ($location.search().promotionCode) {
            myConfig.promotionCode = $location.search().promotionCode.replace(/\s/g,'');
            document.body.classList.add('promo_'+myConfig.promotionCode);
        }

        if ($location.search().ra) {
            myConfig.ra = $location.search().ra;
        }

        if ($location.search().guid) {
            myConfig.guid = $location.search().guid;
        }

        if ($rootScope.lang !== selectedLang) {
            $rootScope.lang = selectedLang;
        }

        document.documentElement.lang = selectedLang;
    }

    selectLanguage();

    // SIDEBAR AUTOFIX

    var windowWidth = $window.innerWidth;
    var lastWindowPos = 0;
    var top = false;
    var bottom = false;

    function resize() {
        if (state === "booking") {
            return null;
        }
        windowWidth = $window.innerWidth;
        if (1000 > windowWidth) {
            top = bottom = false;
            var reference = "#filtros";
            if (state !== "search") {
                reference = "#booking_budget";
            }
            angular.element(reference).removeAttr('style');
        }
    }

    function scroll() {
        if (state === "booking") {
            return;
        }
        var windowPos = $window.pageYOffset;

        if (1000 > windowWidth) {
            //if (state === "bookingForm") {
            //    if(angular.element("body").hasClass("showBudget")) {
            //        if(windowPos >= angular.element(reference)[0].offsetHeight) {
            //            angular.element("body").removeClass("showBudget");
            //        }
            //    }
            //}
            return;
        }

        var reference = "#filtros";
        if (state !== "search") {
            reference = "#booking_budget";
        }

        var sidebarRoot = angular.element(reference);
        var sidebar = angular.element(reference)[0];

        if (sidebar === undefined) {
            return;
        }

        var sidebarHeight = sidebar.offsetHeight;
        var windowHeight = $window.innerHeight;
        var bodyHeight = Math.max(document.body.scrollHeight, document.body.offsetHeight,
            document.documentElement.clientHeight, document.documentElement.scrollHeight, document.documentElement.offsetHeight);
        var topOffset;

        if (sidebarHeight > windowHeight) {
            if (windowPos > lastWindowPos) {
                if (top) {
                    top = false;
                    topOffset = windowPos;
                    sidebarRoot.attr('style', 'top: ' + topOffset + 'px;');
                } else if (!bottom && windowPos + windowHeight > sidebarHeight + sidebar.offsetTop && sidebarHeight < bodyHeight) {
                    bottom = true;
                    sidebarRoot.attr('style', 'position: fixed; bottom: 0;');
                }
            } else if (windowPos < lastWindowPos) {
                if (bottom) {
                    bottom = false;
                    topOffset = windowPos + sidebar.offsetTop;
                    sidebarRoot.attr('style', 'top: ' + topOffset + 'px;');
                } else if (!top && windowPos < sidebar.offsetTop) {
                    top = true;
                    sidebarRoot.attr('style', 'position: fixed;');
                }
            } else {
                top = bottom = false;
                topOffset = ( sidebar.offsetTop > 0 ) ? sidebar.offsetTop : 0;
                sidebarRoot.attr('style', 'top: ' + topOffset + 'px;');
            }
        } else {
            top = true;
            sidebarRoot.attr('style', 'position: fixed;');
        }

        lastWindowPos = windowPos;
    }

    $window.addEventListener('scroll', scroll);

    function resizeAndScroll() {
        resize();
        scroll();
    }

    var resizeTimer;
    $window.addEventListener('resize', function () {
        if (resizeTimer) {
            clearTimeout(resizeTimer);
        }
        resizeTimer = setTimeout(resizeAndScroll, 500);
    });

}
